import React from 'react';
import Layout from '../layouts/index';

class NotFoundPage extends React.Component {
  render() {
    return (
      <Layout>
        <div className="not-found">
          <h1>404 Not Found</h1>
          <h4>You just hit a page that doesn&#39;t exist...</h4>
        </div>
      </Layout>
    );
  }
}

export default NotFoundPage;
